import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';

import { useWindowDimensions } from 'controller/hooks/hooks';
import { useLocation } from 'react-router-dom';

import pako from 'pako';
import { handleOpenExternalPage } from 'scripts/utils/Utils';

// import IframeResizer from 'iframe-resizer-react';

const OneShotLessonPage = () => {

  const location = useLocation();
  const base64Data = decodeURIComponent(new URLSearchParams(location.search).get('data'));

  const byteArray = new Uint8Array(atob(base64Data).split('').map(char => char.charCodeAt(0)));
  const decompressedData = pako.inflate(byteArray, { to: 'string' });

  const dimensions = useWindowDimensions();

  const { url, box, description, price } = JSON.parse(decompressedData);

  const [adjustedIframeHeight, setAdjustedIframeHeight] = useState('750px');

  const [popupClosed, setPopupClosed] = useState(false);

  useEffect(() => {
    if (dimensions.width < 400) {
      setAdjustedIframeHeight("1200px");
    } else if (dimensions.width < 450) {
      setAdjustedIframeHeight("1170px");
    } else if (dimensions.width < 600) {
      setAdjustedIframeHeight("1150px");
    } else if (dimensions.width < 700) {
      setAdjustedIframeHeight("990px");
    } else if (dimensions.width < 800) {
      setAdjustedIframeHeight("920px");
    } else if (dimensions.width < 950) {
      setAdjustedIframeHeight("790px");
    } else {
      setAdjustedIframeHeight("750px");
    }
  }, [dimensions]);


  useEffect(() => {
    const timer = setTimeout(() => {
      handleOpenExternalPage(url, setPopupClosed);
    }, 3500);

    return () => clearTimeout(timer);
  }, [url]);

  return (
    <div id="page-container" page="box-list">

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      {/* <section className="container p-0">
          <h1 className="page-title">Experimenta</h1>
          <hr className="hr-m" />
          <section className="container-m">
            <div className="flex-row align-center gap-l justify-center">
              <h2 className="uppercase text-accent text-outline-thicker text-center">{description}</h2><h2 className="uppercase text-accent">{price}€</h2>
            </div>
            <hr className="hr-s"/>
            <h3 className="uppercase text-center text-gray-dark">{`Box de ${box}`}</h3>
          </section>
          <hr className="hr-l"/>
          <section className="container-sm">
            {description === "1h Open Box" ? (
                <>
                  <p className="text-l text-white-offset text-center">
                  Preenche os dados abaixo para escolheres o dia no qual queres experimentar treinar na box.
                  <br/>
                  A Open Box permite acesso livre a uma box dentro dos horários: 8h35 - 12h00, 13h05 - 18h00 e 20h10 - 21h10
                  </p>
                </>
            ) : 
              <>
                <p className="text-l text-white-offset text-center">
                Preenche os dados abaixo para escolheres a aula queres experimentar.
                <br/>
                Temos <Link to={ROUTES.LESSONS} className="inline-link underline-accent">muitas</Link> por onde escolher!
                </p>
              </>
            }
          </section>
          <hr className="hr-xl hr-l-on-breakpoint-m" />

          <section className="container-s">
            {url &&
              <>
                <input type="hidden" className="class_regy" id="src_regy6" value="https://www.regibox.pt/admin2/modulos/crm/site_integrations/aula_avulso.php?id_box=259&id_int=6&acs=a3586633e7ef09f0e8944a85a0b89ef2&site=s" />
                <iframe name="frame_regy6" id="frame_regy6" width="100%" height="0px" frameBorder="0" scrolling="no"></iframe>
              </>
            }
        </section>

  
          <hr className="hr-xl" />
          <hr className="hr-xxl" />
        </section> */}

      <section className="container p-0">
        <h1 className="page-title">Experimenta</h1>
        <hr className="hr-m" />
        <section className="container-m">
          <div className="flex-row align-center gap-l justify-center">
            <h2 className="uppercase text-accent text-outline-thicker text-center">{description}</h2>{price !== "" && <h2 className="uppercase text-accent">{price}€</h2>}
          </div>
          <hr className="hr-s" />
          <h3 className="uppercase text-center text-gray-dark">{`Box de ${box}`}</h3>
        </section>

        <hr className="hr-l" />
        {popupClosed &&
          <>
            <hr className="hr-l" />
            <section className="container flex-column text-center align-center">
              <i className="icon-check-circle text-accent text-xxxl"></i>
              <hr className="hr-s" />
              <h1 className="text-white-offset">Compra processada</h1>
              <hr className="hr-m" />
              <h2 className="text-gray">Podes preparar-te para o treino!</h2>
              <hr className="hr-m" />
              <p className="text-gray">Receberás um email de confirmação dentro de momentos</p>
            </section>
          </>
        }

        {/* <div className="justify-center">
            <a href={url} target="_blank" className="btn-l btn-primary-ghost">Abrir formulário</a>
          </div> */}

        {!popupClosed &&
          <section className="container-xs">
            {description === "1h Open Box" ? (
              <>
                <p className="text-l text-white-offset text-center">
                  Preenche o formulário através do nosso parceiro para escolheres o dia no qual queres experimentar treinar na box.
                  <br />
                  A Open Box permite acesso livre a uma box dentro dos horários: 8h35 - 12h00, 13h05 - 18h00 e 20h10 - 21h10
                </p>
              </>
            ) :
              <>
                <p className="text-l text-white-offset text-center">
                  Preenche o formulário através do nosso parceiro para escolheres a aula que queres experimentar
                  <br />
                  Temos <Link to={ROUTES.LESSONS} className="inline-link underline-accent">muitas</Link> por onde escolher!
                </p>
              </>
            }
            <hr className="hr-xl hr-l-on-breakpoint-m" />
            <div className="flex-column align-center gap-m justify-center text-center text-white-offset">
              <p>
                Deverá abrir uma janela pop-up dentro de segundos. Se não abrir, por favor verifica que tens as definições de popup aceites.
                Para completares a compra, é necessário completar os campos de formulário, escolher a aula e processar o pagamento dentro da janela pop-up. Podes depois fechar a janela.
              </p>
              {/* <p>Se não abrir uma nova pagina em segundos por favor verifica que tens as definições de popup aceites</p> */}
              {/* <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/popup.png`} /> */}
            </div>
          </section>
        }


        <hr className="hr-xl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default OneShotLessonPage;
