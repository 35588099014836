import React from "react";

import { useModal, MODAL_TYPES } from "controller/providers/ModalContext";
import { SUBSCRIPTIONS_TYPE } from "scripts/Constants";


function SubscriptionComponent({ subscription, frequency, box }) {

  let price;
  let frequencyLabel;


  switch (frequency) {
    case 'year_option':
      price = subscription.normal_year_cost === "-1" ? "N/A" : subscription.normal_year_cost;
      frequencyLabel = 'Por Ano';
      break;
    case 'semestre_option':
      price = subscription.normal_semester_cost === "-1" ? "N/A" : subscription.normal_semester_cost;
      frequencyLabel = 'Por Semestre';
      break;
    case 'trimestre_option':
      price = subscription.normal_trimester_cost === "-1" ? "N/A" : subscription.normal_trimester_cost;
      frequencyLabel = 'Por Trimestre';
      break;
    case 'monthly_option':
      price = subscription.normal_monthly_cost === "-1" ? "N/A" : subscription.normal_monthly_cost;
      frequencyLabel = 'Por Mês';
      break;
    default:
      price = subscription.normal_monthly_cost === "-1" ? "N/A" : subscription.normal_monthly_cost;
      frequencyLabel = 'Por Mês';
      break;
  }

  const { handleShow, handleClose } = useModal();

  return (
    subscription.recursive ? (
      /* PACKS */
      <div className="subscription-item" data-featured={subscription.featured}>
        <figure className="subscription-item--image-container">
          {subscription.featured ? (
            <h6 className="subscription-item--featured-text">Mais vendido</h6>
          ) : null
          }
          <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/subscriptions/sthenos-fit-pack-${subscription.slug}.png`} alt='' />
        </figure>
        <div className="subscription-item-content">
          <h2 className="subscription-item-content--price">{price}<sup>{price === "N/A" ? "" : "€"}</sup></h2>
          <h6 className="subscription-item-content--price-frequency">{frequencyLabel}</h6>
          <hr className="hr-l" />
          <h5>{subscription.name}</h5>
          <hr className="hr-ml" />
          <ul className="subscription-item-content--description">
            {subscription.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <hr className="hr-ml" />
          <div className="mt-auto">
            <button type="button" className="btn btn-primary-ghost btn-alt" onClick={() =>
              handleShow(MODAL_TYPES.BOX_SELECTOR, { handleClose, url_carnaxide: subscription.url_carnaxide, url_benfica: subscription.url_benfica, box, type: SUBSCRIPTIONS_TYPE.PACK, description: subscription.name })}>Aderir
            </button>
          </div>
          {/* <hr className="hr-s"/>
          <p className="text-s text-gray">A tua box selecionada é {box}</p> */}
        </div>
      </div>
    ) : (
      /* AVULSO */
      <div className="subscription-item" data-featured={subscription.featured} data-type="one-shot">
        <div className="subscription-item-content">
          <h2 className="subscription-item-content--price">{subscription.normal_monthly_cost}<sup>€</sup></h2>
          <hr className="hr-m" />
          <h5>{subscription.name}</h5>
          <hr className="hr-ml" />
          <ul className="subscription-item-content--description">
            {subscription.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <hr className="hr-ml" />
          <button
            type="button"
            className="btn btn-primary-ghost btn-alt"
            onClick={() => {
              if (subscription.id === 5) {
                handleShow(MODAL_TYPES.BOX_SELECTOR, {
                  handleClose,
                  url_carnaxide: subscription.url_carnaxide,
                  url_benfica: subscription.url_benfica,
                  box,
                  type: SUBSCRIPTIONS_TYPE.PACK,
                  description: subscription.name
                });
              } else {
                handleShow(MODAL_TYPES.BOX_SELECTOR, {
                  handleClose,
                  url_carnaxide: subscription.url_carnaxide,
                  url_benfica: subscription.url_benfica,
                  box,
                  type: SUBSCRIPTIONS_TYPE.ONE_SHOT,
                  description: subscription.name,
                  price: subscription.normal_monthly_cost
                });
              }
            }}
          >
            Comprar
          </button>

        </div>
      </div>
    )
  );
}

export default SubscriptionComponent;